import { get, post, put, patch, del } from '@/utils/request'
import { downloadHandler } from '@/utils'
import { Player } from '@/views/standard/international'

// eventCode
export const teamGet = data => {
  return get('team-name-relation/names', data, 'json')
}
export const teamSave = data => {
  return post('team-name-relation/save', data, 'json')
}
export const getEventCodeList = data => {
  return post('eventCode/list', data, 'json')
}
export const addEventCode = data => {
  return post('eventCode/saveStandardEventCode', data, 'json')
}
export const editEventCode = data => {
  return put('eventCode/updateStandardEventCode', data, 'json')
}

export const editMatchRegion = data => {
  return put(`match/updateRegionId?matchId=${data.matchId}&regionId=${data.regionId}`, data, 'json')
}

// eventType
export const getEventTypeList = data => {
  return post('eventType/list', data, 'json')
}
export const addEventType = data => {
  return post('eventType/saveStandardEventType', data, 'json')
}
export const editEventType = data => {
  return put('eventType/updateStandardEventType', data, 'json')
}

// sport
export const getSportList = data => {
  return post('enum/sportList', data, 'json')
}
export const addSport = data => {
  return post('sport/saveSport', data, 'json')
}
export const editSport = data => {
  return put('sport/updateStandardSport', data, 'json')
}

// region
export const getRegionList = data => {
  return post('region/list', data, 'json')
}
export const addRegion = data => {
  return post('region/saveRegion', data, 'json')
}
export const editRegion = data => {
  return put('region/updateStandardRegion', data, 'json')
}

// team
export const getTeamList = data => {
  return post('team/list', data, 'json')
}
export const addTeam = data => {
  return post('team/saveTeam', data, 'json')
}
export const editTeam = data => {
  return put('team/updateTeam', data, 'json')
}
export const editTeamRemark = data => {
  console.log(data)
  return put('team/remark', data, 'formData')
}

export const editTeamRegion = data => {
  return put('team/region', data, 'formData')
}

// 联赛
export const autoCreateMatchDatasource = data => {
         return post(
           'tournament/update/autoCreateMatchDatasource' +
             `?tournamentId=${data.tournamentId}&datasourceCode=${data.datasourceCode}`,
           data,
           'json'
         )
       }
export const autoPush = data => {
         return post(
           'tournament/update/autoPush' +
             `?tournamentId=${data.tournamentId}&autoPush=${data.autoPush}`,
           data,
           'json'
         )
       }
export const uploadTournament = (data) => {
  return post('tournament/upload', data, 'upload')
}
export const exportTournament = data => {
  return get('tournament/export', data, 'down').then((res) => {
    downloadHandler(res)
  })
}
export const getTournamentList = data => {
  return post('tournament/list', data, 'json')
}
export const addTournament = data => {
  return post('tournament/saveTournament', data, 'json')
}

// 修改remark
export const editTournament = data => {
  return put('tournament/changeTournament', data, 'json')
}

// 玩法 market type
export const getMarketTypeList = data => {
  return post('enum/marketTypeList', data, 'json')
}
export const addMarketType = data => {
  return post('marketType/saveMarketType', data, 'json')
}
export const editMarketType = data => {
  return put('marketType/changeMarketType', data, 'json')
}

export const queryMarketTypes = data => {
  return get('enum/marketTypeList', data, 'json')
}

// option
export const getOptionList = data => {
  return post('enum/optionList', data, 'json')
}
export const addOption = data => {
  return post('option/saveOption', data, 'json')
}
export const editOption = data => {
  return put('option/updateStandardOption', data, 'json')
}

// period
export const getPeriodList = data => {
  return post('enum/periodList', data, 'json')
}
export const addPeriod = data => {
  return post('period/savePeriod', data, 'json')
}
export const editPeriod = data => {
  return put('period/updateStandardPeriod', data, 'json')
}

// metch  赛事
export const getMatchList = data => {
  return post('match/list', data, 'json')
}
export const createOutrightMatch = (data) => {
  return post('match/createOutrightMatch', data, 'json')
}
export const validateOutrightMatch = (data) => {
  return post('match/validateOutrightMatch', data, 'json')
}
export const createStandardMatch = (data) => {
  return post('match/createStandardMatch', data, 'json')
}
export const validateStandardMatch = (data) => {
  return post('match/validateStandardMatch', data, 'json')
}
export const matchFormatList = data => {
  return get('enum/matchFormatList', data, 'json')
}

export const editMatchStatus = data => {
  return put(
    `match/updateDataStatus?matchId=${data.matchId}&dataStatus=${data.dataStatus}`
  )
}
export const thirdDataStatus = data => {
  return put(
    `thirdmatch/updateDataStatus?thirdMatchId=${data.thirdMatchId}&dataStatus=${data.dataStatus}`
  )
}
//  查询赛事基础数据国际化
export const getMatchLanguage = matchId => {
  return get(`language/findMatchId?matchId=${matchId}`)
}

// 更新hot
export const editMatchHot = (matchId, hot) => {
  return put(`match/updateMatchHot?matchId=${matchId}&hot=${hot}`)
}
export const updateAnimateStatus = (matchId, animateStatus) => {
  return put(
    `match/updateAnimateStatus?matchId=${matchId}&animateStatus=${animateStatus}`
  )
}
export const updatePlayerNumberStatus = (matchId, status) => {
  return put(
    `match/updatePlayerNumberStatus?matchId=${matchId}&status=${status}`
  )
}
export const updateVideoStatus = (matchId, videoStatus) => {
  return put(
    `match/updateVideoStatus?matchId=${matchId}&videoStatus=${videoStatus}`
  )
}

// 三方赛事

export const getSubMarches = matchId => {
  return get(`thirdmatch/getList?matchId=${matchId}`)
}

// 赛事 事件

export const getEventList = data => {
  return post('event/list', data, 'json')
}
// 赛果
export const getMatchResultList = data => {
  return post('matchResult/list', data, 'json')
}

// 国际化
// 联赛
export const queryTournamentInternational = data => {
  return post('language/queryTournamentInternational', data, 'json')
}
export const editInternational = data => {
  return put('language/edit', data, 'json')
}
export const addInternational = (data) => {
  return put('language/add', data, 'json')
}
// 运动
export const querySportTypeInternational = data => {
  return post('language/querySportTypeInternational', data, 'json')
}
export const editSportTypeInternational = data => {
  return put('language/editSportTypeInternational', data, 'json')
}

// 地区
export const querySportRegionInternational = data => {
  return post('language/querySportRegionInternational', data, 'json')
}
export const editSportRegionInternational = data => {
  return put('language/editSportRegionInternational', data, 'json')
}

// 球队
export const querySportTeamInternational = data => {
  return post('language/querySportTeamInternational', data, 'json')
}
export const editSportTeamInternational = data => {
  return put('language/editSportTeamInternational', data, 'json')
}

// eventcode

export const queryEventCodeInternational = data => {
  return post('language/queryEventCodeInternational', data, 'json')
}
export const editEventCodeInternational = data => {
  return put('language/editEventCodeInternational', data, 'json')
}
// eventtype
export const queryEventTypeInternational = data => {
  return post('language/queryEventTypeInternational', data, 'json')
}
export const editEventTypeInternational = data => {
  return put('language/editEventTypeInternational', data, 'json')
}

// markettype
export const queryMarketTypeInternational = data => {
  return post('language/queryMarketTypeInternational', data, 'json')
}
export const editMarketTypeInternational = data => {
  return put('language/editMarketTypeInternational', data, 'json')
}
// outright
export const querySrOrBcOutrightMarketTypeInternational = data => {
  return post('language/querySrMarketLanguageInternational', data, 'json')
}
export const querySrOrBcOutrightOptionInternational = data => {
  return post('language/querySrMarketLanguageInternational', data, 'json')
}

// Period

export const queryPeriodInternational = data => {
  return post('language/queryPeriodInternational', data, 'json')
}
export const editPeriodInternational = data => {
  return put('language/editPeriodInternational', data, 'json')
}

export const queryTournamentPhaseInternational = data => {
  return post('language/queryTournamentPhaseInternational', data, 'json')
}
export const queryPlayerInternational = data => {
  return post('language/queryPlayerInternational', data, 'json')
}
// Option
export const queryOptionInternational = data => {
  return post('language/queryOptionInternational', data, 'json')
}
export const queryCancelReason = (data) => {
  return post('language/queryCancelReason', data, 'json')
}
export const queryCancelReasonDetail = (data) => {
  return post('language/queryCancelReasonDetail', data, 'json')
}
export const queryResultTypeGroup = (data) => {
  return post('language/queryResultTypeGroup', data, 'json')
}
export const editOptionInternational = data => {
  return put('language/editStandardOptionInternational', data, 'json')
}
// 赛事阶段国际化
export const queryMatchPeriodInternational = data => {
  return post('language/queryMatchPeriodInternational', data, 'json')
}
export const editMatchPeriodInternational = data => {
  return put('language/editMatchPeriodInternational', data, 'json')
}
export const uploadLang = data => {
  return post('language/upload', data, 'upload')
}
// 赛事状态国际化
export const queryMatchStatusInternational = data => {
  return post('language/queryMatchStatusInternational', data, 'json')
}
export const editMatchStatusInternational = data => {
  return put('language/editMatchStatusInternational', data, 'json')
}

//体育规则
export const sportRuleList = (data) => {
  return post('sportRule/edit', data, 'json')
}
export const sportRulePublish = (data) => {
  return post('sportRule/publish', data, 'json')
}
export const sportRuleSave = (data) => {
  return post('sportRule/save', data, 'json')
}
export const sportRuleDel = (data) => {
  return del('sportRule/delete?id=' + data)
}

// 赛事阶段
export const getMatchPeriodList = data => {
  return post('enum/matchPeriodList', data, 'json')
}
export const addMatchPeriod = data => {
  return post('standardMatchPeriod/saveMatchPeriod', data, 'json')
}
export const editMatchPeriod = data => {
  return put('standardMatchPeriod/updateStandardMatchPeriod', data, 'json')
}

// 赛事状态
export const getMatchStatusList = data => {
  return post('enum/matchStatusList', data, 'json')
}
export const addMatchStatus = data => {
  return post('matchStatus/savePeriod', data, 'json')
}
export const editMatchStatusData = data => {
  return put('matchStatus/updateStandardPeriod', data, 'json')
}

// match 中立场
export const updateNeutralGround = data => {
  return put(`match/updateNeutralGround?matchId=${data.matchId}&neutralGround=${data.neutralGround}`)
}

// 查询语言
export const findRegionId = data => {
  return get(`language/findRegionId?regionId=${data.regionId}`, 'json')
}
export const exportExcel = data => {
  return get(`language/export?libraryType=${data.libraryType}`, '', 'down').then(res => {
    downloadHandler(res,'', 'zip')
  })
}
// 查询语言
export const deleteMatch = data => {
  return patch(`thirdmatch/unmapping?thirdMatchId=${data.thirdMatchId}`, 'json')
}

// 更改标准赛事时间
export const editTime = data => {
  return patch(`match/updateMatchInfo`, data, 'json')
}

// 更改标准赛事状态
export const changMatchStatus = data => {
  return patch(`match/updateMatchStatus?matchId=${data.matchId}&matchStatus=${data.matchStatus}`, data, 'json')
}
// 根据标准地区id查询所有第三方地区信息
export const getThirdSportRegionById = data => {
  return post(`region/getThirdSportRegionById`, data, 'formData')
}
// 解除第三方地区和标准地区的匹配关系
export const updateThirdSportRegionById = data => {
  return post(`region/updateThirdSportRegionById`, data, 'formData')
}
// 修改地区数据有效/无效
export const regionValidity = data => {
  return post(`region/update/regionValidity?regionId=${data.regionId}&validity=${data.validity}`)
}
// 根据标准运动id查询所有第三方运动信息
export const getThirdSportById = data => {
  return post(`sport/getThirdSportById`, data, 'formData')
}
// 解除第三方运动和标准运动的匹配关系
export const updateThirdSportById = data => {
  return post(`sport/updateThirdSportById`, data, 'formData')
}
// 修改体育数据有效/无效
export const sportValidity = data => {
  return put(`sport/update/sportValidity`, data, 'json')
}
// 根据标准球队id查询所有第三方球队信息
export const getThirdTeamById = data => {
  return post(`team/getThirdTeamById`, data, 'formData')
}
// 解除第三方球队和标准球队的匹配关系
export const updateThirdTeamById = data => {
  return post(`team/updateThirdTeamById`, data, 'formData')
}
// 修改球队数据有效/无效
export const teamValidity = data => {
  return post(`team/update/teamValidity`, data, 'formData')
}
// 根据标准联赛id查询所有第三方联赛信息
export const getThirdTournamentById = data => {
  return post(`tournament/getThirdTournamentById`, data, 'formData')
}
// 解除第三方联赛和标准联赛的匹配关系
export const updateThirdThirdTournamentById = data => {
  return post(`tournament/updateThirdThirdTournamentById`, data, 'formData')
}
// 修改联赛数据有效/无效
export const tournamentValidity = data => {
  return post(`tournament/update/tournamentValidity`, data, 'formData')
}
export const tournamentHot = (data) => {
  return post(`tournament/update/tournamentHot`, data, 'formData')
}
export const getAllLanguages = data => {
  return post(`language/getAllLanguages`, data, 'json')
}
// Player
export const getPlayerFromPlayerMarkets = data => {
  return post(`player/getPlayerFromPlayerMarkets`, data, 'json')
}
export const standardPlayerList = (data) => {
  return post(`player/standardPlayerList`, data, 'json')
}
export const savePlayer = (data) => {
  return post(`player/savePlayer`, data, 'json')
}
export const updateStandardPlayer = (data) => {
  return post(`player/updateStandardPlayer`, data, 'json')
}
export const remarkPlayer = (data) => {
  return post(`player/remark`, data, 'formData')
}
export const updateVisiblePlayer = (data) => {
  return post(`player/updateVisible`, data, 'formData')
}
export const getThirdPlayerById = (data) => {
  return post(`player/getThirdPlayerById`, data, 'formData')
}
export const updateThirdPlayerById = (data) => {
  return post(`player/unmap`, data, 'formData')
}

//重新推送赛事
export const matchRePush = (matchId) => {
  return put(
    `match/rePush?matchId=${matchId}`
  )
}

//重新推送赛事
export const regionRePush = (matchId) => {
  return put(
    `region/rePush?standardRegionId=${matchId}`
  )
}

//重新推送赛事
export const teamRePush = (matchId) => {
  return put(
    `team/rePush?standardTeamId=${matchId}`
  )
}

//重新推送赛事
export const tournamentRePush = (matchId) => {
  return put(
    `tournament/rePush?standardTournamentId=${matchId}`
  )
}

//重新推送赛事
export const playerRePush = (matchId) => {
  return put(
    `player/rePush?standardPlayerId=${matchId}`
  )
}